.app {
  --primary-color: #FD5520;
  --primary-color-2: #FF6C39;
  --link-color: #167AFA;
  --primary-text-color: #000000;
  --default-text-color: #5C6B83;
  --secondary-text-color: #e5e7eb;
  --error-color: var(--primary-color-2);

  --control-v-padding: 16px;
  --control-border-radius: 0.75rem;
  --control-font-size: 16px;
  --control-line-height: 1.5;

  --input-background-color: #f7fafb;
  --input-border-color: #e8e8e8;
  --input-placeholder-color: #8fa4ac;

  --button-background-color: var(--primary-color);
  --button-background-color_on_hover: var(--primary-color-2);
  --button-text-color: white;

  --form-message-font-size: 12px;
  --form-message-line-height: 1.2;
  --form-label-font-size: 16px;

  --gap-small: 8px;
  --gap-default: 16px;

  /* background-color: #f5f5f5; */
  /* padding: 64px; */
  
}

.button {
  background-color: var(--button-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: var(--control-border-radius);
  padding: var(--control-v-padding);
  font-size: var(--control-font-size);
  line-height: var(--control-line-height);
  color: var(--button-text-color);
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.button:enabled:hover {
  background-color: var(--button-background-color_on_hover);
}
.button:disabled {
  filter: grayscale(1);
}

.app * {
  box-sizing: border-box;
  outline: none;
}

.gap_size_default {
  margin-top: var(--gap-default);
}