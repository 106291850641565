.text {
  font-weight: 300;
  line-height: 1.3;
  margin: 0.24em 0;
}

.text_type_primary {
  color: var(--primary-text-color);
}
.text_type_secondary {
  color: var(--secondary-text-color);
}

.text_size_large {
  font-size: 24px;
}
.text_size_default {
  font-size: 16px; 
}

.text_align_center {
  text-align: center;
}

/* .text + .text {
  margin: 0.24em;
} */