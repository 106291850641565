.form-block {
  display: flex;
  flex-direction: column;
}

.form-block__item {
  flex: 1;
  padding: 0 var(--gap-small);
}
.form-block__item_for_label {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0.36em;
  color: var(--primary-text-color);
  font-size: var(--form-label-font-size);
}
.form-block__item_for_message {
  display: flex;
  padding-top: 0.36em;
}

.form-block + .form-block {
  margin-top: var(--gap-default);
}

@media (min-width: 768px) {
  .form-block {
    flex-direction: row;
  }
  .form-block__item_for_label {
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    padding-bottom: 0;
  }
  .form-block__item_for_message {
    align-items: center;
    padding-top: 0;
  }
}