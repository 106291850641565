.input {
  position: relative;
}

.input__control {
  box-shadow: none;
  background-image: none;
  background-clip: padding-box;
  background-color: var(--input-background-color);
  border: 1px solid;
  border-radius: var(--control-border-radius);
  padding: var(--control-v-padding);
  font-size: var(--control-font-size);
  line-height: var(--control-line-height);
  color: var(--default-text-color);
  text-align: center;
  width: 100%;
}

.input__control::placeholder {
  color: var(--input-placeholder-color);
}

.input__control.input__control_has-error_no {
  border-color: var(--input-border-color);
}

.input__control.input__control_has-error_yes {
  border-color: var(--input-border-color_on_error);
}

.input__control_type_password {
  padding-left: calc(var(--control-v-padding) * 3);
  padding-right: calc(var(--control-v-padding) * 3);
}

.input__button {
  position: absolute;
  top: var(--control-v-padding);
  right: var(--control-v-padding);
  cursor: pointer;
  width: 1.6em;
  height: auto;
  fill: var(--default-text-color);
}