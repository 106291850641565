.form-message {
  font-size: var(--form-message-font-size);
  line-height: var(--form-message-line-height);
}

.form-message_type_help-text {
  color: var(--text-color);
}
.form-message_type_error {
  color: var(--error-color);
}